
.container{
    margin-top: 80px;
    margin-bottom: 10px;

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.divBtn{
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: end;
}
.fileDrop{
    width: 600px;
    height: 300px;
    background-color:  #e1e1e1;
    border: dashed 4px rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.preview{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
   
 

}

.image{
    width: 360px;
    height: 250px;
    overflow: hidden;
    margin: 10px;
    position: relative;
}

.image img{
    width: 100%;
    height: 100%;

}

.lupaContent{position: absolute; height: 100%; width: 100%; opacity: 0; display: flex; justify-content:center; align-items: center;}

.lupaContent:hover {
    opacity: 1;
}
