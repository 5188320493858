.container{
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    
}

.imgDiv{
   width: 100%;
   height: 98%;
   border-bottom-left-radius: 60px;
}

.textField{
    width: 64%;
    border-radius: 5px;
    margin-bottom: 12px;
}

.erroLogin{
    color: red;
}

.boxContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.btn{
    color: #fff;
    width: 100px;
    background-color: #367f59;
}